<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="3">
      <Select v-model="model1" @on-change="chooseType">
        <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      </Col>
      <Col span="5">
        <div>
          <Input placeholder="请输入查询条件" v-model="storePrams.name"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker placeholder="时间查询" v-model="storePrams.create_time" style="width: 100%;"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button>
        </div>
      </Col>
    </Row>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button v-if="jurisdictionList.indexOf('admin-agent-add') !== -1" icon="md-add" type="primary" @click="addUser('add')">新增代理商</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="worlkColumns" :data="data6">
      <template slot-scope="{ row, index }" slot="operation">
        <Button type="info" ghost size="small" style="margin-right: 5px" @click="changeMsg(row)">次数修改</Button>
        <Button v-if="jurisdictionList.indexOf('admin-agent-update') !== -1" type="primary" size="small" style="margin-right: 5px" @click="show('edit',row)">编辑</Button>
        <Button v-if="jurisdictionList.indexOf('admin-agent-del') !== -1" type="error" size="small" @click="remove(index,row)" style="margin-right: 5px">删除</Button>
        <Button v-if="jurisdictionList.indexOf('admin-agent-config') !== -1" size="small" @click="changeConfigure(index,row)">配置权限</Button>
      </template>
      <template slot-scope="{ row }" slot="status">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 5px">当前状态({{ row.admininfo.it_disabled=== 0 ?'已启用':'已禁用' }})</div>
          <Button type="warning" size="small" v-if="row.admininfo.it_disabled === 0" style="margin-right: 5px" @click="changeOff(row)">禁用</Button>
          <Button type="success" size="small" v-else style="margin-right: 5px" @click="changeOn(row)">启用</Button>
        </div>
      </template>
    </Table>
    <br/>
    <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange"
          :page-size="storePrams.limit"/>
    <Modal
        :title="formTitle"
        v-model="showModel"
        :mask-closable="false"
        width="600"
        :footer-hide="true"
        @on-ok="submit"
        @on-cancel="cancel"
        class-name="vertical-center-modal">
      <Form  ref="formItem" :rules="ruleValidate" :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="账户" prop="account">
          <Input v-model="formItem.account" placeholder="请输入代理商账户"></Input>
        </FormItem>
        <FormItem label="密码" prop="password">
          <Input v-model="formItem.password" type="password" placeholder="请输入代理商密码"></Input>
        </FormItem>
        <FormItem label="姓名" prop="agent_name">
          <Input v-model="formItem.agent_name" placeholder="请输入代理商姓名"></Input>
        </FormItem>
        <FormItem label="联系方式" prop="phone">
          <Input v-model="formItem.phone" placeholder="请输入联系方式"></Input>
        </FormItem>
        <FormItem label="所属公司" prop="company_name">
          <Input v-model="formItem.company_name" placeholder="请输入所属公司"></Input>
        </FormItem>
        <FormItem label="平台数量" prop="store_num">
          <Input v-model="formItem.store_num" type="number" placeholder="请输入可创建的平台数量"></Input>
        </FormItem>
        <FormItem label="有效期">
          <Select v-model="formItem.change" placeholder="请选择公告类型">
            <Option value='0'>永久</Option>
            <Option value="TIME">选择时间</Option>
          </Select>
        </FormItem>
        <FormItem v-if="formItem.change === 'TIME'">
          <DatePicker v-model="formItem.date" type="date" placeholder="请选择平台有效期" style="width: 380px"></DatePicker>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formItem')">确认</Button>
          <Button @click="handleReset('formItem')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        title="配置权限"
        v-model="configure"
        :mask-closable="false"
        width="500"
        @on-ok="submitConfigure"
        @on-cancel="cancelConfigure"
        class-name="vertical-center-modal">
      <Form :model="formConfigure" :label-width="120" style="width: 450px">
        <FormItem label="云储配置">
          <i-switch v-model="formConfigure.cloud_status" size="large" @on-change="changeFirst">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="OEM配置">
          <i-switch v-model="formConfigure.oem_status" size="large" @on-change="changeThird">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="短信配置">
          <i-switch v-model="formConfigure.sms_status" size="large" @on-change="changeSecond">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        title="编辑次数"
        v-model="editMessage"
        :mask-closable="false"
        width="600"
        :footer-hide="true"
        class-name="vertical-center-modal">
      <Form ref="messageForm" :rules="ruleMessageForm" :model="messageForm" :label-width="140" style="width: 500px">
        <FormItem label="已认证合同次数" prop="contract_number">
          <Input v-model="messageForm.contract_number" type="number" placeholder="请输入合同次数"></Input>
        </FormItem>
        <FormItem label="未认证合同次数" prop="not_contract_number">
          <Input v-model="messageForm.not_contract_number" type="number" placeholder="请输入合同次数"></Input>
        </FormItem>
        <FormItem label="短信次数" prop="sms_number" v-if="userInfo.level == 0">
          <Input v-model="messageForm.sms_number" type="number" placeholder="请输入短信次数"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleMessage('messageForm')">确认</Button>
          <Button @click="resetMessage('messageForm')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
import {agentList, agentDel, agentAdd, agentUpdate, agentInfo, agentBan,agentSetup,agentUpdateScnum} from '../../api/agent'
import moment from "moment";

export default {
  data() {
    var checkphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (!this.isCellPhone(value)) {
        callback(new Error('请输入正确的手机号!'))
      } else {
        callback()
      }
    };
    return {
      worlkColumns: [
        {
          title: '账户',
          width: 160,
          render: (h, params) => {
            return h('div', [
              h('div', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, params.row.admininfo.account)
            ]);
          }
        },
        {
          title: '姓名',
          width: 160,
          key: 'contacts_name'

        },
        {
          title: '联系方式',
          width: 160,
          key: 'tel'

        },
        {
          title: '所属公司',
          width: 180,
          key: 'danwei'
        },
        {
          title: '平台数量',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('div', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, params.row.admininfo.store_num)
            ]);
          }
        },
        {
          title: '已认证合同次数',
          render: (h, params) => {
            return h('div', params.row.admininfo.contract_num)
          },
          width: 150,
        },
        {
          title: '未认证合同次数',
          render: (h, params) => {
            return h('div', params.row.admininfo.not_contract_num)
          },
          width: 150,
        },
        {
          title: '短信次数',
          render: (h, params) => {
            return h('div', params.row.admininfo.sms_num)
          },
          width: 100,
        },
        {
          title: '状态',
          width: 200,
          slot: 'status',
        },
        {
          title: '有效期',
          width: 200,
          render: (h, params) => {
            return h('div', [
              h('div', {
                props: {
                  type: 'text',
                  size: 'small'
                }
              }, params.row.admininfo.expiration_time)
            ]);
          }
        },
        {
          title: '创建时间',
          key: 'created_at',
          width: 200,
        },
        {
          title: '操作',
          key: 'action',
          width: 300,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      cityList: [
        {
          value: '0',
          label: '姓名、联系方式、所属公司'
        },
        {
          value: '1',
          label: '账户查询'
        }
      ],
      model1: '1',
      editMessage:false,
      messageForm: {
        sms_number: '',
        contract_number: '',
        not_contract_number: '',
        user_id:''
      },
      ruleMessageForm:{
        contract_number: [
          {required: true, message: '请输入已认证合同次数', trigger: 'blur'}
        ],
        not_contract_number: [
          {required: true, message: '请输入未认证合同次数', trigger: 'blur'}
        ],
        sms_number: [
          {required: true, message: '请输入短信次数', trigger: 'blur'}
        ],
      },
      data6: [],
      total:0,
      configure:false,
      storeId:'',
      userId:'',
      showModel: false,
      formItem: {
        account: '',
        change: '0',
        password: '',
        date: '',
        agent_name: '',
        company_name: '',
        store_num: '',
        expiration_time: '',
        phone: '',
        agent_id:'',
        user_id:''
      },
      ruleValidate: {
        account: [
          { required: true, message: '请输入账户', trigger: 'blur' },
          { type: 'string', min: 3,max:12, message: '公告标题不得少于三个字符超过十二个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        agent_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: '请输入所属公司', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: checkphone,trigger: 'blur' },
        ],
        store_num: [
          { required: true, message: '请输入平台数量', trigger: 'blur' }
        ]
      },
      formTitle: '新增代理商',
      storePrams: {
        limit: 10,
        page: 1,
        name: '',
        create_time: '',
        type:'1'
      },
      formConfigure:{
        cloud_status:false,
        sms_status:false,
        oem_status:false,
        synopsis:''
      },
      jurisdictionList:[],
      userInfo:{}
    }
  },
  created() {
    this.getList()
    this.jurisdictionList = this.$store.state.auth
    this.userInfo = this.$store.state.userInfo
  },
  mounted() {
  },
  methods: {
    isCellPhone (val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false
      } else {
        return true
      }
    },
    changeMsg(row){
      this.messageForm.sms_number=String(row.admininfo.sms_num)
      this.messageForm.contract_number=String(row.admininfo.contract_num)
      this.messageForm.not_contract_number=String(row.admininfo.not_contract_num)
      this.messageForm.user_id=row.admininfo.id
      this.editMessage =true
    },
    handleMessage(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          agentUpdateScnum(this.messageForm).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
            this.editMessage = false
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        } else {
          return
        }
      })
    },
    resetMessage(name){
      this.$refs[name].resetFields();
    },
    chooseType(e){
      this.storePrams.type = e
    },
    getList() {
      agentList(this.storePrams).then(res => {
        this.data6 = res.data.data
        this.total = res.data.total
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    changeType(){
      agentSetup(this.storePrams).then(res => {
        console.log(res)
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    searchList() {
      this.storePrams.create_time = moment(this.storePrams.create_time).format('YYYY-MM-DD')
      if (this.storePrams.create_time == 'Invalid date') {
        this.storePrams.create_time = ''
      }
      this.storePrams.page = 1
      this.getList()
    },
    show( type,row) {
      if (type === 'edit') {
        this.formTitle = '编辑代理商信息'
      }
      agentInfo({agent_id:row.id}).then(res=>{
        if(res.data.admininfo.expiration_time === '永久'){
          this.formItem.change = '0'
        }else {
          this.formItem.change = 'TIME'
          this.formItem.date=res.data.admininfo.expiration_time
        }
        this.formItem.agent_id = res.data.agent_id
        this.formItem.user_id = res.data.admininfo.user_id
        this.formItem.phone=res.data.tel
        this.formItem.account=res.data.admininfo.account
        this.formItem.agent_name=res.data.contacts_name
        this.formItem.company_name=res.data.danwei
        this.formItem.store_num=String(res.data.admininfo.store_num)
      }).catch(res => {
        this.$Message.error(res.msg)
      })
      this.showModel = true
    },
    remove( index,row) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此条代理商，删除后无法恢复！！！',
        onOk: () => {
          agentDel({user_id: row.admininfo.id, agent_id: row.id}).then(res => {
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {

        }
      });
    },
    addUser(type) {
      if (type === 'add') {
        this.formTitle = '新增代理商'
      }
     // this.reset()
      this.showModel = true
    },
    changeOff(row){
      if(this.jurisdictionList.indexOf('admin-agent-disabled') !== -1) {
        this.$Modal.confirm({
          title: '禁用提示',
          content: '请问您是否确认禁用此条代理商账户',
          onOk: () => {
            if(row.admininfo.it_disabled === 0){
              let changeType = 1
              agentBan({user_id:row.admininfo.id,type:changeType}).then(res=>{
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res=>{
                this.$Message.error(res.msg)
              })
            }
          },
          onCancel: () => {
          }
        });
      }
    },
    changeOn(row){
      if(this.jurisdictionList.indexOf('admin-agent-disabled') !== -1) {
        this.$Modal.confirm({
          title: '启用提示',
          content: '请问您是否确认启用此条代理商账户',
          onOk: () => {
            if(row.admininfo.it_disabled === 1){
              let changeType = 0
              agentBan({user_id:row.admininfo.id,type:changeType}).then(res=>{
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res=>{
                this.$Message.error(res.msg)
              })
            }
          },
          onCancel: () => {
          }
        });
      }
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.formItem.change === '0') {
            this.formItem.expiration_time = 0
          } else {
            this.formItem.expiration_time = moment(this.formItem.date).format('YYYY-MM-DD')
          }
          if (this.formItem.agent_id) {
            agentUpdate(this.formItem).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          } else {
            agentAdd(this.formItem).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.showModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          }
        } else {
          this.$Message.error('请输入代理商信息');
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    submit() {

    },
    cancel() {
      this.reset()
    },
    reset(){
      this.formItem.agent_name = ''
      this.formItem.company_name=''
      this.formItem.store_num=''
      this.formItem.expiration_time=''
      this.formItem.phone=''
      this.formItem.agent_id=''
      this.formItem.user_id='0'
      this.formItem.change='0'
      this.formItem.password=''
      this.formItem.date=''
      this.formItem.account=''
    },
    submitConfigure(){
    },
    cancelConfigure(){

    },
    changeConfigure(index,row){
      if(row.admininfo.cloud_status === 1){
        this.formConfigure.cloud_status = true
      }else {
        this.formConfigure.cloud_status = false
      }
      if(row.admininfo.sms_status === 1){
        this.formConfigure.sms_status = true
      }else {
        this.formConfigure.sms_status = false
      }
      if(row.admininfo.oem_status === 1){
        this.formConfigure.oem_status = true
      }else {
        this.formConfigure.oem_status = false
      }
      this.userId = row.admininfo.id
      this.configure = true
    },
    changeFirst(e){
      let status
      if(e === true){
         status = 1
      }else {
        status = 0
      }
      agentSetup({type:2,status:status,user_id:this.userId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeSecond(e){
      let status
      if(e === true){
        status = 1
      }else {
        status = 0
      }
      agentSetup({type:3,status:status,user_id:this.userId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeThird(e){
      let status
      if(e === true){
        status = 1
      }else {
        status = 0
      }
      agentSetup({type:1,status:status,user_id:this.userId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
  }
}
</script>
