import request from '@/utils/request'

export function agentList (data) {
    return request({
        url: '/agent/list',
        method: 'get',
        params: data
    })
}
export function agentDel (data) {
    return request({
        url: '/agent/del',
        method: 'post',
        data
    })
}
export function agentAdd (data) {
    return request({
        url: '/agent/add',
        method: 'post',
        data
    })
}
export function agentUpdate (data) {
    return request({
        url: '/agent/update',
        method: 'post',
        data
    })
}
export function agentInfo (data) {
    return request({
        url: '/agent/get_info',
        method: 'get',
        params: data
    })
}
export function agentBan(data) {
    return request({
        url: '/agent/is_ban',
        method: 'post',
        data
    })
}
export function agentSetup(data) {
    return request({
        url: '/agent/setup_config',
        method: 'post',
        data
    })
}

export function agentUpdateScnum(data) {
    return request({
        url: '/agent/update_scnum',
        method: 'post',
        data
    })
}